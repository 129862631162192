





















import { Component, Mixins } from 'vue-property-decorator'
import { Card, DataTable, DataTableColumn, TagBox, HttpGet, HttpPost, Action, Searchbox } from '../../lib/mavejs'
import { url } from '@/functions'
import { SearchPaginationResetMixin } from '@/mixins/SearchPaginationResetMixin'

@Component({ components: { Card, DataTable, DataTableColumn, TagBox, HttpGet, HttpPost, Action, Searchbox }, methods: { url } })
export default class Index extends Mixins(SearchPaginationResetMixin) {
  page = 1
  search = ''
  changed = [];

  filters = {
    search: { column: 'name', value: '' }
  }

  detect (row) {
    this.changed = this.changed.filter(item => item.id !== row.id)
    this.changed.push(row)

    this.$forceUpdate()
  }

  paginate (currentPage) {
    this.page = currentPage
  }

  tagSaveSuccess (id, success) {
    if (success) {
      this.changed = this.changed.filter(item => item.id !== id)
    }
  }
}
